/* eslint no-console:0 */

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import '../stylesheets/session.scss'

/**
 * Start Rails js engine
 */
Rails.start()

/**
 * Start Turbolinks
 */
Turbolinks.start()
